<template>
  <v-container
    fluid
  >

    <v-row>
      <v-col>
        <v-btn
          @click="dialogAddLearner = true"
        >
          Add Learner
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="group.users"
          :items-per-page="15"
        >
        <!--<template v-slot:item.context="{ item }"> 

          <v-menu
            offset-y
          >

            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>

            <v-list>

              <v-list-item 
                @click="showActivateCourseDialog(item)" 
                style="cursor: pointer;"
              >
                <v-list-item-title>Activate Course</v-list-item-title>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item 
                @click="showUpdateUserDialog(item);" 
                style="cursor: pointer;"
              >
                <v-list-item-title>Update User</v-list-item-title>
              </v-list-item>

              <v-list-item 
                @click="showChangePasswordDialog(item);" 
                style="cursor: pointer;"
              >
                <v-list-item-title>Change Password</v-list-item-title>
              </v-list-item>

            </v-list>

          </v-menu>

        </template>    -->   
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dialog: Add Learner -->

    <v-dialog
      v-model="dialogAddLearner"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Add Learner
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >

            <v-text-field
              v-model="learnerFirstname"
              label="First Name"
            ></v-text-field>         

            <v-text-field
              v-model="learnerSurname"
              label="Surname"
            ></v-text-field>

            <v-text-field
              v-model="learnerUsername"
              label="Username"
            ></v-text-field>

            <v-text-field
              v-model="learnerPassword"
              label="Password"
            ></v-text-field>

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogAddLearner = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="addLearner"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import errorUtils from '@/utils/errorUtils'
  import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  const GroupRepository = RepositoryFactory.get('group');

  export default {
    name: 'Group',

    components: {
      //courseExplorer: () => import("@/components/CourseExplorer"),
      //lessonViewer: () => import("@/components/LessonViewer"),
    },

    data: () => ({
      headers: [      
        {
          text: 'Role',
          value: 'grouproledescription',
        },        
        {
          text: 'Surname',
          value: 'surname',
        },
        {
          text: 'Firstname',
          value: 'firstname',
        },
        {
          text: 'Username',
          value: 'username',
        },  
        {
          value: 'context',
        },              
      ],        
      group: {},
      dialogAddLearner: false,
      learnerFirstname: '',
      learnerSurname: '',
      learnerUsername: '',
      learnerPassword: '',
    }),

    methods: {

      async loadGroup() {

        const { data } = await GroupRepository.getGroup(this.$route.params.groupid);
        if (data.success) {
          this.group = data.data.group;
        } else {          
          errorUtils.showError(data.message);
        }
      },

      async addLearner() {

        const { data } = await GroupRepository.addLearner(
          this.$route.params.groupid,
          this.learnerFirstname, 
          this.learnerSurname, 
          this.learnerUsername,
          this.learnerPassword
        );
        if (data.success) {                 
          this.learnerFirstname = ''; 
          this.learnerSurname = ''; 
          this.learnerUsername = ''; 
          this.learnerPassword = ''; 

          this.dialogAddLearner = false;
          this.loadGroup();

        } else {
          errorUtils.showError(data.message);
        }

      },

    },

    mounted() {
      this.loadGroup();
    }

  }
</script>
